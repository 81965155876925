





















































import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { CHANGE_MODULE_AVAILABILITY, GET_MODULES } from '@/store/employee/actions.type'
import { Modul, Modules } from '@/store/employee/types'
import { Form, KeyValue } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
import BaseForm from '@/components/forms/BaseForm.vue'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import { SimpleServerResponse, User } from '@/store/types'
import { REFRESH, UPDATE_LOGGED_IN_USER } from '@/store/user/actions.type'
import { UserServerObject } from '@/store/user/types'
import { compareByKey, parseUserServerObjectToUser } from '@/common/globals'
import MetaComponent from '@/common/MetaComponent.vue'
  @Component({ components: { BaseForm, ConfirmationModal } })
export default class ModuleManagement extends MetaComponent {
  @Action(GET_MODULES)
  public getModules: () => Promise<Array<Modul>>

  @Action(CHANGE_MODULE_AVAILABILITY)
  public changeModuleAvailability: (dto: Modul[]) => Promise<SimpleServerResponse>

  @Action(REFRESH)
  public refresh: () => Promise<UserServerObject>

  @Action(UPDATE_LOGGED_IN_USER)
  public updateLoggedInUser: (user: User) => Promise<void>

  modules: Modules = {
    module: [],
    modulesFrontendModel: []
  }

  allModules: Array<Modul> = []

  get modulesCheckBoxGroupOptions (): Array<KeyValue> {
    return this.allModules.map(module => {
      return {
        text: this.$i18n.t('module-management.module-names.' + module.name).toString(),
        value: module.moduleId
      } as KeyValue
    }).sort((a, b) => compareByKey(a, b, 'text'))
  }

  get writeable (): boolean {
    return hasRight(Rights.MODULE_MANAGEMENT)
  }

  get readonly (): boolean {
    return hasRight(Rights.MODULE_MANAGEMENT)
  }

  openModal (): void {
    this.$bvModal.show('confirmation-modal-confirm-module-changes')
  }

  save (): void {
    this.modules.module = this.allModules.filter(module => {
      if (module.available && !this.modules.modulesFrontendModel.includes(module.moduleId)) {
        module.available = false
        return module
      }
      if (!module.available && this.modules.modulesFrontendModel.includes(module.moduleId)) {
        module.available = true
        return module
      }
    })
    this.$root.$emit('load')
    this.changeModuleAvailability(this.modules.module).then(data => {
      this.$root.$emit('alert', this.$i18n.t('module-management.' + data.message))
      this.refresh().then((userServerObject: UserServerObject) => {
        this.updateLoggedInUser(parseUserServerObjectToUser(userServerObject))
      })
    }).catch(error => {
      this.$root.$emit('alert', this.$i18n.t('module-management.' + error.message), '', true)
    }).finally(() => {
      this.$root.$emit('end-load')
    })
  }

  get fields (): Form[] {
    return [
      {
        id: 'modules',
        key: 'modulesFrontendModel',
        type: InputTypes.CHECKBOXGROUP,
        label: this.$i18n.t('module-management.modules').toString(),
        options: this.modulesCheckBoxGroupOptions,
        md: 12
      }
    ]
  }

  mounted (): void {
    this.$root.$emit('load')
    this.getModules().then((data: Array<Modul>) => {
      this.allModules = data
      this.modules.modulesFrontendModel = this.allModules.map(module => {
        if (module.available) {
          return module.moduleId
        } else {
          return ''
        }
      }).filter(module => module)
    }).finally(() => {
      this.$root.$emit('end-load')
    })
  }
}
